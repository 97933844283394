import React from "react";
import theme from "../theme";
import classes from "../styles/home";
import { isEmpty } from "../general/utils";
import { Navigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PieChartIcon from "../img/pieChart.png";
import { isMobile } from "react-device-detect";
import BarChartIcon from "../img/barChart.png";
import PerProductIcon from "../img/berries.png";
import CalibrationIcon from "../img/scaleIcon.png";
import { LOADING } from "../general/requestStates";
import ProductivityIcon from "../img/productivity.png";
import OverviewIcon from "../img/overviewDashboardIcon.png";
import ReportIcon from "../components/containers/ReportIcon";
import StackedBarChartIcon from "../img/stackedBarChart.png";
import DivergingBarChartIcon from "../img/divergingBarChart.png";
import WasteIcon from "../img/waste.png";
import { Typography, Grid, CircularProgress } from "@mui/material";


class Home extends React.Component {
  state = {
    updates: [<div><CircularProgress size="1em" style={{display: "inline-block", marginRight:"1em"}}/> 
              <Typography variant='h5' style={{display: "inline-block"}}> Loading Resources...</Typography></div>
    ]
  };

  componentDidMount() {
    const { getSessionData, getProducts, products, getSessionDataRS, getProductsRS } = this.props;
    if (getSessionDataRS !== LOADING) {
      getSessionData();    
    }
    if (isEmpty(products) && getProductsRS !== LOADING){
      getProducts();
    }
  }

  navigateUser() {
    const { oktaUser } = this.props
    if (oktaUser) {
      return <Navigate to="/oktaLogin" />
    }
    return <Navigate to="/login" />
  }

  render() {
    const { user, getPandasRS} = this.props;
    const {updates} = this.state

    if (!ReportIcon){
      return (
        <div style={{padding:"2em", marginLeft:"2em"}}>
            <Grid item style={classes(theme, isMobile).reportContainer}>
                {updates}
            </Grid>
        </div>
      )
    }
    return (
      <div>
        {getPandasRS === LOADING ? "Hello" : <Grid>
          <Grid item style={{...classes(theme, isMobile).reportContainer}}>
            <Typography style={classes(theme, isMobile).heading}>
              {" "}
              Select a report to generate{" "}
            </Typography>
            <Grid container direction="row" justifyContent="center">
                  <div hidden={user && !user['overview_dashboard_access']}>
                  <ReportIcon
                    icon={OverviewIcon}
                    title="Overview Dashboard"
                    url={"/home/overviewDashboard"}
                  />
                  </div>
                  <div hidden={user && !user['order_and_line_status_access']}>
                  <ReportIcon
                    icon={PieChartIcon}
                    title="Order and Line Status"
                    url={"/home/orderLineStatus"}
                  />
                  </div>
                  <div hidden={user && !user['station_and_operator_performance_access']}>
                  <ReportIcon
                    icon={StackedBarChartIcon}
                    title="Station and Operator Performance"
                    url={"/home/stationOperatorPerformance"}
                  />
                  </div>
                  <div hidden={user && !user['station_and_operator_packing_speed_access']}>
                  <ReportIcon
                    icon={BarChartIcon}
                    title="Station and Operator Packing Speed"
                    url={"/home/stationOperatorPackingSpeed"}
                  />
                  </div>
                  <div hidden={user && !user['station_and_operator_waste_access']}>
                  <ReportIcon
                    icon={WasteIcon}
                    title="Station and Operator Waste"
                    url={"/home/stationOperatorWaste"}
                  />
                  </div>
                  <div hidden={user && !user['station_and_operator_giveaway_access']}>
                  <ReportIcon
                    icon={DivergingBarChartIcon}
                    title="Station and Operator Giveaway"
                    url={"/home/stationOperatorGiveaway"}
                  />
                  </div>
                  <div hidden={user && !user['line_productivity_access']}>
                  <ReportIcon
                    icon={ProductivityIcon}
                    title="Line Productivity"
                    url={"/home/lineProductivity"}
                  />
                  </div>
                  <div hidden={user && !user['per_product_productivity_access']}>
                  <ReportIcon
                    icon={PerProductIcon}
                    title="Per Product Productivity"
                    url={"/home/perProductProductivity"}
                  />
                  </div>
                  <div hidden={user && !user['calibration_report_access']}>
                  <ReportIcon
                    icon={CalibrationIcon}
                    title="Calibration Report"
                    url={"/home/calibrationReport"}
                  />
                  </div>
            </Grid>  
          </Grid>
        </Grid>}
      </div>
    );
  }
}

export default styled(Home)({ theme });
