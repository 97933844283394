import React from "react";
import theme from '../theme';
import classes from '../styles/login';
import { isEmpty } from '../general/utils';
import { Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { isMobile } from "react-device-detect";
import ergopakLogo from '../img/Ergopak-logo.png';
import panoramaLogo from '../img/panorama_bb_blue.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { inputLabelClasses } from "@mui/material/InputLabel";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FAILED, LOADING, SUCCEEDED } from '../general/requestStates';
import { Button, CircularProgress, Grid, Typography, TextField, IconButton } from '@mui/material';

class Login extends React.Component {

  state = {
    username: "",
    password: "",
    redirect: false,
    showPassword: false,
  };


  componentDidMount() {
    const {getSessionData, logOutRS} = this.props;
    document.addEventListener("keydown", this.handleKeyDown);
    if (logOutRS !== LOADING) {
      getSessionData();
    };
  };


  componentDidUpdate(prevProps){
    const { user, loginRS, submitData } = this.props;
    var date = new Date();
    var today = date.getTime();

    /*  Makes an API call to the users table and updates the amount of times a user had 
        tried to log in with an incorrect password  */
    // if (loginRS === SUCCEEDED && prevProps.loginRS === LOADING && !isEmpty(user) && 
    //     user.error === 'password' && user.nr_login_attempts < 3) 
    // { 
    //   let sid = user.sid;
    //   let nr_login_attempts = user.nr_login_attempts + 1;
    //   let last_login_attempt = today;
    //   let data = {sid, nr_login_attempts, last_login_attempt};
    //   submitData({ database: "ergopak_applications", table: "user", record: data });
    // }

    /*  Makes an API call to the users table and resets the amount of times a 
        user has tried to log in, as they have now logged in with the correct credentials */
    // else if (loginRS === SUCCEEDED && prevProps.loginRS === LOADING && !isEmpty(user) && 
    //           user.error === '' && user.nr_login_attempts < 3)
    // {
    //   let sid = user.sid;
    //   let nr_login_attempts = 0;
    //   let last_login_attempt = today;
    //   let data = {sid, nr_login_attempts, last_login_attempt};
    //   submitData({ database: "ergopak_applications", table: "user", record: data });
    // };
  };


  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  };


  handleKeyDown = (target) => {
    if (target.keyCode === 13) {
      this.handleLogIn();
    };
  };


  renderRedirect = () => {
    const { user } = this.props;
    let _ = require("underscore");
    if (!_.isEmpty(user)) {
      return <Navigate to={"/"} />;
    };
  };


  /*  Handles the login process by making an API call to the database to validate the 
      user's credentials */
  handleLogIn() {
    const {username, password} = this.state;
    this.props.login({username, password});
  };


  /*  Takes the user input and stores it in its relevant state */
  handleLoginForm(key, event) {
    let current = { ...this.state };
    current[key] = event.target.value;
    this.setState({ ...current });
  };


  /*  Determines what login error message needs to display depending on the error */
  loginWarningMessage(){
    const { user, loginRS, sessionExpired } = this.props;
    var message;
    var date = new Date().toISOString();
    var today = new Date()
    var url = window.location.href + "/okta";
    var link = <a href= {url} style={classes(theme, isMobile).oktaWarning}>Okta Login</a>;

    if (loginRS === FAILED){
      message = <Typography style={classes(theme, isMobile).warning} align="center">
                  Can't connect to server. Please contact your administrator.</Typography>
    }
    else if (!isEmpty(user) && user.error === 'password' &&
            loginRS === SUCCEEDED){
      message = <Typography style={classes(theme, isMobile).warning}>
                  Incorrect password. Please try again.</Typography>;
    }
    else if (!isEmpty(user) && user.error === 'blocked' && user.nr_login_attempts === 3 && 
            (user.last_login_attempt + 30000) !== today && loginRS === SUCCEEDED){
      message = <Typography style={classes(theme, isMobile).warning}>
                  You have been temporarily been locked from your account. Try again later</Typography>;
    }
    else if (!isEmpty(user) && user.error === 'username' && loginRS === SUCCEEDED){
      message = <Typography style={classes(theme, isMobile).warning}>
                  User does not exist. Please try again.</Typography>;
    }
    else if (!isEmpty(user) && user.error === 'okta' && loginRS === SUCCEEDED){
      message = <Typography style={classes(theme, isMobile).warning}>
                  This user is registered to an Okta account. Please redirect to the {link} page.
                </Typography>;
    }
    else if (!isEmpty(user) && user.error === 'both' && loginRS === SUCCEEDED){
      message = <Typography style={classes(theme, isMobile).warning}>
                  Incorrect username/password. Please try again.</Typography>;
    }
    else if (sessionExpired && isEmpty(user)){
      message = <Typography style={classes(theme, isMobile).warning}>
                  Your session has expired due to inactivity. Please log in again to continue.</Typography>
    }
    else if (!isEmpty(user) && user.error === "cluster" && loginRS === SUCCEEDED && isEmpty(user.scope)){
      message = <Typography style={classes(theme, isMobile).warning}>
                  Your account has not been assigned a cluster. Please contact your administrator.</Typography> 
    }
    return message
  }


  render() {
    const { user, loginRS } = this.props;
    const { showPassword } = this.state;
    var date = new Date();
    var today = date.getTime();
    var loginMessage = this.loginWarningMessage();
    var redirect = isEmpty(user) || !user.scope || (user.nr_login_attempts === 3 && 
                  (user.last_login_attempt + 30000) !== today) ? "" : <Navigate to="/home" replace={true}/>


    return(
      <Grid container direction="column" style={classes(theme, isMobile).background}>
        <Grid container direction="row" style={classes(theme, isMobile).header}>
          <img style={classes(theme, isMobile).panoramaLogo} src={panoramaLogo} alt="panorama-logo" />
        </Grid>
        <div style={{marginTop: "5%"}}>
        <Grid container direction="column" alignItems="center" justifyContent="center" style={classes(theme, isMobile).loginContainer}>
          <Grid container direction="row" >
            <Typography style={classes(theme, isMobile).loginText}>
              Please log in with your email address and password 
            </Typography>
          </Grid>

          <Grid container direction="row" alignItems="center" justifyContent="center">
            <TextField
              type="email"
              label="Email"
              variant="standard"
              value={this.state.username}
              inputProps={{style: {color: "white"}}}
              style={classes(theme, isMobile).emailField}
              onChange={this.handleLoginForm.bind(this, "username")}
              InputLabelProps={{sx: {color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`]:{color: "#FFFFFF"}}}}
            />
          </Grid>

          <Grid container direction="row" alignItems="center" justifyContent="center">
            <TextField
              label="Password"
              variant="standard"
              value={this.state.password}
              inputProps={{style: {color: "white"}}}
              type={showPassword ? "string" : "password"}
              style={classes(theme, isMobile).passwordField}
              onChange={this.handleLoginForm.bind(this, "password")}
              InputLabelProps={{sx: {color: "#FFFFFF", [`&.${inputLabelClasses.shrink}`]:{color: "#FFFFFF"}}}}
            />

          </Grid>
          <IconButton 
            style={classes(theme, isMobile).showPassword}
            color="primary" aria-label="upload picture" component="label" 
            onClick={(e) => this.setState({ showPassword: !showPassword })}
          > 
            <VisibilityIcon style={{color: 'white'}} sx={{ display: showPassword ? "none" : null}}/>
          </IconButton> 

          <IconButton 
            style={classes(theme, isMobile).hidePassword}
            color="primary" aria-label="upload picture" component="label" 
            onClick={(e) => this.setState({ showPassword: !showPassword })}
          > 
            <VisibilityOffIcon style={{color: 'white'}} sx={{ display: !showPassword ? "none" : null}}/>
          </IconButton> 

          {loginMessage}

          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={this.handleLogIn.bind(this)}
              style={classes(theme, isMobile).loginButton}
            >   
            {loginRS === LOADING ? <CircularProgress color="white" size="2em" /> : "Log In"}
            </Button>
          </Grid>
          
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <img style={classes(theme, isMobile).ergopakLogo} src={ergopakLogo} alt="Ergopak-Logo" />
          </Grid>
        </Grid>
        {redirect}
        </div>
      </Grid>
    );
  }
}

export default styled(Login)({ theme });
