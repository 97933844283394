import React from "react";
// import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client';
import App from "./App";

import { Provider } from "react-redux";
import store from "./app/store";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store} style={{margin: "0em", padding: "0em"}}>
    <App style={{margin: "0em", padding: "0em"}}/>
  </Provider>
);