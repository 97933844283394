import React from "react";
import theme from "../theme";
import { Navigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {isEmpty, isEqual } from "../general/utils";
import { SUCCEEDED, LOADING } from "../general/requestStates";
import classes from "../styles/components/reportSetup";
import DownloadIcon from "@mui/icons-material/Download";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StationOperatorPerformance from "./stationOperatorPerformance";
import StationOperatorGiveaway from "./stationOperatorGiveaway";
import OverviewDashboard from "../components/overviewDashboard";
import ProductivityReport from "../components/containers/ProductivityReport";
import OrderLineStatus from "./orderLineStatus";
import StationOperatorPackingSpeed from "../components/stationOperatorPackingSpeed";
import StationOperatorWaste from "../components/stationOperatorWaste";
import { isMobile } from "react-device-detect";
import { Button, Grid, Autocomplete, TextField as MuiTextField, TextField, Typography, CircularProgress, IconButton} from "@mui/material";
import CalibrationReport from "./calibrationReport";
import { getReportOptions, getReportStates } from "../general/reportFiltering"
import spokie from '../img/spokie.png';
import RefreshIcon from '@mui/icons-material/Refresh';


class reportSetup extends React.Component {
  pandasReady = false
  graph = null
  sites = null
  timer = null
  ordersBeforeGenerate = null
  dateFromValid = true
  dateToValid = true
  dateWarning = ""
  state = {
    dateFrom: new Date().getTime(),
    dateTo:new Date().getTime(),
    redirect: null,
    clients: [],
    countries: [],
    sites: [],
    orders: [],
    products: [],
    buildings: [],
    computers: [],
    lines: [],
    pandasReady: false,
    showFilters: false,
    newPandas: false
  };


  componentDidMount() {
    const { filterSelections, getProducts, interval, products, productShow } = this.props;
    let state = {...filterSelections}
    
    const loadReport = this.loadReport.bind(this)
    if (interval) {
      this.timer = setInterval(loadReport, interval * 1000)
      this.loadReport()   
    }

    if (isEmpty(products) && productShow) {
      getProducts()
    } 

    this.setState({...state})
  }

  componentDidUpdate(prevProps){
    const { getPandasRS, getFilterOptionsRS, pandas, filterSelections} = this.props
    if (getPandasRS === SUCCEEDED && prevProps.getPandasRS === LOADING) {
      let newPandas = !isEqual(pandas, prevProps.pandas)
      this.setState({pandasReady: true, newPandas})
    } 
    else if (getFilterOptionsRS === SUCCEEDED && prevProps.getFilterOptionsRS === LOADING) {
      this.setState({})
    } 
    else if (!isEqual(filterSelections, prevProps.filterSelections)){
      this.setState({...filterSelections})
    }
  }

  componentWillUnmount(){
    const { setFilterSelections, orderShow, productShow } = this.props
    const { buildings, clients, computers, countries, dateFrom, dateTo, lines, orders, products, sites } = this.state
    
    if (this.timer) {
      clearInterval(this.timer)
    }
    
    if (orderShow){
      setFilterSelections({buildings, clients, computers, countries, dateFrom, dateTo, lines, orders, sites})
    }
    else if (productShow){
      setFilterSelections({buildings, clients, computers, countries, dateFrom, dateTo, lines, products, sites})
    }
    else{
      setFilterSelections({buildings, clients, computers, countries, dateFrom, dateTo, lines, sites})
    }
  }

  // when user clicks on generate report
  loadReport(){
    const { getPandas, reportName, setFilterSelections, latestRequestId, setLatestReportFilters, setPdfFilters, orderShow, productShow, user } = this.props;
    const { clients, countries, sites, buildings, computers, orders, lines, products } = this.state;
    let selections = {clients, countries, sites, buildings, computers, lines, orders}
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, lineOptions, orderOptions] = getReportOptions(user.scope, selections)
    let line
    !lines.length ? line = lineOptions : line = lines
    

    let allStartDates = []
    let allEndDates = []
    let dateStart = this.state.dateFrom
    let dateEnd = this.state.dateTo

    if (orders.length){
      if (orders.length > 1) {
        for (let i=0; i < orders.length; i++) {
          allStartDates.push(new Date(orders[i]['StartDate']).getTime())
          allEndDates.push(new Date(orders[i]['EndDate']).getTime())
        }
        const min = allStartDates.reduce((a, b) => Math.min(a, b));
        const max = allEndDates.reduce((a, b) => Math.max(a, b));

        dateStart = min
        dateEnd = max
    }

      else {
        let orderStartDate = orders[0]['StartDate']
        let orderEndDate = orders[0]['EndDate']

        orderStartDate = new Date(orderStartDate).getTime()
        orderEndDate = new Date(orderEndDate).getTime()
        dateStart = orderStartDate
        dateEnd = orderEndDate
      }
    }





    // let lineState = this.state.lines
    let c = [countries];
    let s = [sites];
    this.sites = null
    this.graph = null
    let dateFrom = Number.isInteger(dateStart) ? new Date(dateStart) : dateStart
    let dateTo = Number.isInteger(dateEnd) ? new Date(dateEnd) : dateEnd
    let filterData

    if (orderShow){
      filterData = {dateFrom: new Date(dateFrom).toJSON(), dateTo: new Date(dateTo).toJSON(), 
        orders, c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, orders, lines, reportName})
      setLatestReportFilters({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, orders, lines, reportName})
    }
    else if (productShow) {
      filterData = {dateFrom: new Date(dateFrom).toJSON(), dateTo: new Date(dateTo).toJSON(), 
        products, c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, products, lines, reportName})
      setLatestReportFilters({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, products, lines, reportName})
    }
    else if (reportName === "Overview Dashboard"){
      filterData = {c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({clients, countries, sites, buildings, computers, products, lines, reportName})
      setLatestReportFilters({clients, countries, sites, buildings, computers, products, lines, reportName})
    }
    else {
      filterData = {dateFrom: new Date(dateFrom).toJSON(), dateTo: new Date(dateTo).toJSON(), 
        c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers,  lines, reportName})
      setLatestReportFilters({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, lines, reportName})
    }
    getPandas({ filterData });
    setPdfFilters({ filterData });
    this.setState({newPandas: false})
  }

  //if the report contains a table
  tableReportToGenerate() {
    const { reportName, pandas } = this.props;
    let displayReport = null;
      if (reportName === "Overview Dashboard") {
        displayReport = <OverviewDashboard data={pandas}  />;
      } else if (reportName === "Line Productivity") {
          displayReport = <ProductivityReport data={pandas} />;
      } else if (reportName === "Per Product Productivity") {
          displayReport = <ProductivityReport data={pandas} />;
      } else if (reportName === "Calibration Report") {
        displayReport = <CalibrationReport data={pandas}/>;
    }
    return displayReport
  }

  //if the report contains a chart
  graphReportToGenerate() {
    const { reportName, hideFilters} = this.props;
    let displayReport = null;
      if (reportName === "Order and Line Status") {
        displayReport = <OrderLineStatus hideFilters={hideFilters} />;
      } else if (reportName === "Station and Operator Performance") {
        displayReport = <StationOperatorPerformance hideFilters={hideFilters} />;
      } else if (reportName === "Station and Operator Packing Speed") {
        displayReport = <StationOperatorPackingSpeed hideFilters={hideFilters} />;
      }
        else if (reportName === "Station and Operator Waste") {
        displayReport = <StationOperatorWaste hideFilters={hideFilters} />;
      } else if (reportName === "Station and Operator Giveaway") {
        displayReport = <StationOperatorGiveaway hideFilters={hideFilters} />;
      }
      this.graph = <div style={classes(theme).graph}> {displayReport} </div>
  }

  // generates the correct heading for the report
  generateReportHeadings() {
    const { pandas } = this.props;   
    this.sites = null
    let elements = []
    let all_user_lines = pandas.map(e => e.line)
    let user_lines = [...new Set(all_user_lines)]
    let displayReport = this.tableReportToGenerate()
    for (let i = 0; i < user_lines.length; i++){
      let displayReport = this.tableReportToGenerate(i)
      let element = <div key={ i }>
          <Typography style={{...classes(theme, isMobile).label, fontSize: "1.2rem", marginBottom: theme.spacing(1), marginLeft: theme.spacing(1)}}> Line: {user_lines[i]}</Typography>
          {displayReport}
        </div>
        elements.push(element)
    }
    this.sites = <div style={{marginBottom: "1rem"}}>{displayReport}</div>
  }

  // generate report
  handleGenerateReport() {
    const { getPandas, reportName, setFilterSelections, latestRequestId, setLatestReportFilters, setPdfFilters, orderShow, productShow, user, refreshUserScope } = this.props;
    const { clients, countries, sites, buildings, computers, orders, lines, products } = this.state;
    let selections = {clients, countries, sites, buildings, computers, lines, orders}
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, lineOptions, orderOptions] = getReportOptions(user.scope, selections)
    let line
    // if (!clients || !countries || !sites.length || !buildings.length || !computers.length) {
    //   lines = this.ordersBeforeGenerate
    // }
    // else{
    //   lines = this.state.lines
    // }
    !lines.length ? line = lineOptions : line = lines
    

    let allStartDates = []
    let allEndDates = []
    let dateStart = this.state.dateFrom
    let dateEnd = this.state.dateTo

    //sets date range to orders date range
    if (orders.length){
      if (orders.length > 1) {
        for (let i=0; i < orders.length; i++) {
          allStartDates.push(new Date(orders[i]['StartDate']).getTime())
          allEndDates.push(new Date(orders[i]['EndDate']).getTime())
        }
        const min = allStartDates.reduce((a, b) => Math.min(a, b));
        const max = allEndDates.reduce((a, b) => Math.max(a, b));

        dateStart = min
        dateEnd = max
    }

      else {
        let orderStartDate = orders[0]['StartDate']
        let orderEndDate = orders[0]['EndDate']

        orderStartDate = new Date(orderStartDate).getTime()
        orderEndDate = new Date(orderEndDate).getTime()
        dateStart = orderStartDate
        dateEnd = orderEndDate
      }
    }





    // let lineState = this.state.lines
    let c = [countries];
    let s = [sites];
    this.sites = null
    this.graph = null
    let dateFrom = Number.isInteger(dateStart) ? new Date(dateStart) : dateStart
    let dateTo = Number.isInteger(dateEnd) ? new Date(dateEnd) : dateEnd
    let filterData

    if (orderShow){
      filterData = {dateFrom: new Date(dateFrom).toJSON(), dateTo: new Date(dateTo).toJSON(), 
        orders, c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, orders, lines, reportName})
      setLatestReportFilters({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, orders, lines, reportName})
    }
    else if (productShow) {
      filterData = {dateFrom: new Date(dateFrom).toJSON(), dateTo: new Date(dateTo).toJSON(), 
        products, c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, products, lines, reportName})
      setLatestReportFilters({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, products, lines, reportName})
    }
    else if (reportName === "Overview Dashboard"){
      filterData = {c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({clients, countries, sites, buildings, computers, products, lines, reportName})
      setLatestReportFilters({clients, countries, sites, buildings, computers, products, lines, reportName})
    }
    else {
      filterData = {dateFrom: new Date(dateFrom).toJSON(), dateTo: new Date(dateTo).toJSON(), 
        c, s, line, buildings, computers, reportName, requestId: latestRequestId};
      setFilterSelections({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers,  lines, reportName})
      setLatestReportFilters({dateFrom: dateFrom, dateTo: dateTo, clients, countries, sites, buildings, computers, lines, reportName})
    }
    getPandas({ filterData });
    setPdfFilters({ filterData });
    this.setState({newPandas: false})
  }
  
  //sets filters values
  handleSelect(key, event, value) {
    let current = { ...this.state };
    const { reportName } = this.props
    if (key === "orders" && reportName !== "Per Product Productivity") {
      let allStartDates = []
      let allEndDates = []
      current.orders = value
      if (!isEmpty(value)){
        if ((value).length > 1){
          for (let i=0; i < value.length; i++) {
            allStartDates.push(new Date(value[i]['StartDate']).getTime())
            allEndDates.push(new Date(value[i]['EndDate']).getTime())
          }
          const min = allStartDates.reduce((a, b) => Math.min(a, b));
          const max = allEndDates.reduce((a, b) => Math.max(a, b));

          current.dateFrom = min
          current.dateTo = max

          this.setState({...current})
          return 
        }
        else {
          let orderStartDate = current.orders[0]['StartDate']
          let orderEndDate = current.orders[0]['EndDate']

          orderStartDate = new Date(orderStartDate).getTime()
          orderEndDate = new Date(orderEndDate).getTime()
          current.dateFrom = orderStartDate
          current.dateTo = orderEndDate

        }
      }
      else {
        current.dateFrom = new Date().getTime()
        current.dateTo = new Date().getTime()
      }
      this.setState({...current})
      return 
    }
    current[key] = value;

    
    
    // this.props.forceGenerate = false
    this.setState({ ...current });
  }

  /**
 * Handles the selection of an option.
 * Sets the state to the new options,
 * causing a re-render.
 */

  //gets the avalible options in the filter dropdown
  handleOptionsSelect(key, event, value) {
    const { user } = this.props
    const { clients, countries, sites, buildings, computers, lines, orders} = this.state;
    let selections = {clients, countries, sites, buildings, computers, lines, orders}
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, lineOptions, orderOptions] = getReportOptions(user.scope, selections, this.state)
    let options = getReportOptions(user.scope, selections)
    this.setState(getReportStates(key, value, selections, user.scope, this.state, options));
  }

  handleLinesSelect(event, value) {
    const {lines} = this.state
    // this.state.lines = value
    this.setState({lines:value})
  }

  //sets the date filter
  handleDateSet(key, value) {
    let current = { ...this.state };
    current[key] = value;  
    this.setState({ ...current });
  }

  //download button for PDF or CSV
  handleDownload(fileType) {
    const { getReportDataFile, reportName } = this.props;
    const { dateFrom, dateTo, orders, sites, buildings, clients, countries, computers, lines } = this.state;
    var newDateFrom = new Date(dateFrom)
    var newDateTo = new Date(dateTo)
    let filterData = {orders, sites, buildings, clients, countries, computers, dateFrom: newDateFrom.toDateString(),
      dateTo: newDateTo.toDateString(),
    };
    getReportDataFile({ filterData, reportName: reportName, fileType });
  }




  //generate any warnings with the filters
  generateWarnings(computerOptions){
    this.dateWarning = ""
    this.dataWarning = ""
    if (!this.dateFromValid){
      this.dateWarning = <Typography color="red" fontSize={theme.spacing(0.8)} style={classes(theme).warning}> Date From should be before Date To </Typography>
      this.dateFromValid = true
    } else if (!this.dateToValid) {
      this.dateWarning = <Typography color="red" fontSize={theme.spacing(0.8)} style={classes(theme).warning}> Date To should be after Date From </Typography>
      this.dateToValid = true
    }
    const { dateFrom, dateTo, computers } = this.state
    
    // let daysOfData = (dateTo - dateFrom)/1000/86400
    // if (daysOfData > 2 && computers.length !== 1 && computerOptions.length !== 1 && this.props.reportName !== "Overview Dashboard") {
    //   this.dataWarning = <Typography color="red" fontSize={theme.spacing(0.8)} style={classes(theme).warning}> Warning: the data from multiple lines over several days may take long to load. Narrow it down to fewer days or one line for faster results. </Typography>
    // }
  }


  render() {
    const {downloadable, reportName, pandas, getPandasRS,
      user, getReportDataFileRS, type, hideFilters, filterSelections, products, orderShow, productShow, interval, latestReportFilters, login, clusterLines, pdf, csv, refreshUserScope} = this.props;
    const { redirect, clients, countries, sites, buildings, orders, computers, dateFrom, dateTo, pandasReady, ordersList, lines } = this.state;
    let allStartDates = []
    let allEndDates = []
    let dateStart = dateFrom
    let dateEnd = dateTo
    let username = user.username
    if (orders.length){
      if (orders.length > 1) {
        for (let i=0; i < orders.length; i++) {
          allStartDates.push(new Date(orders[i]['StartDate']).getTime())
          allEndDates.push(new Date(orders[i]['EndDate']).getTime())
        }
        const min = allStartDates.reduce((a, b) => Math.min(a, b));
        const max = allEndDates.reduce((a, b) => Math.max(a, b));

        dateStart = min
        dateEnd = max
    }
      else {
        let orderStartDate = orders[0]['StartDate']
        let orderEndDate = orders[0]['EndDate']

        orderStartDate = new Date(orderStartDate).getTime()
        orderEndDate = new Date(orderEndDate).getTime()
        dateStart = orderStartDate
        dateEnd = orderEndDate
      }
    }
   

    
    
    
    
    
    
    
    if (redirect) {
      return <Navigate to={redirect} replace={true} />
    } else if (isEmpty(user) || [dateFrom, dateTo].includes(null) ){
      return (
        <div> 
          <CircularProgress />
          <Typography> Loading... </Typography>
        </div>
      )
    }
    
    // let orderSet = new Set(orders)
    // let orderList = Array.from(orderSet);

    let orderList = orders;

    let filteredOrders = []
    if (this.state.lines.length) {
      for (let i = 0; i < this.state.lines.length; i++) {
        for (let j = 0; j < orderList.length; j++) {
          if (orderList[j].order_origin === this.state.lines[i].line_id){
            filteredOrders.push(orderList[j])
          }
        }
      }
    }
    else {
      filteredOrders = orderList
    }

    let currentFilters = {dateFrom: Number.isInteger(dateFrom) ? dateFrom : dateFrom.getTime(), dateTo: Number.isInteger(dateTo) ? dateTo : dateTo.getTime(),
      clients, countries, sites, buildings, computers, orders: this.state.orders, products}

    let selections = {clients, countries, sites, buildings, computers, lines, orders}
    let [clientOptions, countryOptions, siteOptions, buildingOptions, computerOptions, lineOptions, orderOptions] = getReportOptions(user.scope, selections)
     
    
    let dateHidden = reportName === "Overview Dashboard";
    if (pandasReady && type === "table" && !isEmpty(pandas) && getPandasRS !== LOADING ) {
      this.generateReportHeadings()
    } else if (pandasReady && type === "graph" && getPandasRS !== LOADING && !isEmpty(pandas)) {
      this.graphReportToGenerate();
    }
    let feedback
    if (isEmpty(pandas) && getPandasRS === SUCCEEDED){
      this.graph = ""
      this.sites = ""
      feedback = <div> <Grid container direction='column' alignItems='center'>
          <img src={spokie} alt="We wish there was!" style={{height:"80px", marginTop: "1rem", marginBottom: "-1rem" }}/>
          <Typography style={classes(theme).label} > Sorry! No data matches your search criteria. </Typography>
        </Grid></div>
    } else if (getPandasRS === LOADING && (!interval || !pandas)) {
      feedback = <div style={classes(theme, isMobile).label}><Grid container direction="row" alignItems="center" justifyContent="left"> <CircularProgress size={"2rem"}/> <Typography style={classes(theme, isMobile).textField} >Loading data ...</Typography> </Grid></div>
      this.graph = ""
    } else if (this.graph === null && this.sites === null && getPandasRS === LOADING) {
      feedback = <div style={classes(theme, isMobile).label}><Grid container direction="row" alignItems="center" justifyContent="left"> <CircularProgress size={"2rem"}/> <Typography style={classes(theme, isMobile).textField} >Generating {type} ...</Typography> </Grid></div>
    }
    this.generateWarnings(computerOptions)

    // redirects back to home page if user is not allowed to access a report
    let redirection
    if (reportName === "Overview Dashboard" && user['overview_dashboard_access'] === false ||
        reportName === "Order and Line Status" && user['order_and_line_status_access'] === false ||
        reportName === "Station and Operator Performance" && user['station_and_operator_performance_access'] === false ||
        reportName === "Station and Operator Packing Speed" && user['station_and_operator_packing_speed_access'] === false ||
        reportName === "Station and Operator Waste" && user['station_and_operator_waste_access'] === false ||
        reportName === "Station and Operator Giveaway" && user['station_and_operator_giveaway_access'] === false ||
        reportName === "Line Productivity" && user['line_productivity_access'] === false ||
        reportName === "Per Product Productivity" && user['per_product_productivity_access'] === false ||
        reportName === "Calibration Report" && user['calibration_report_access'] === false
    ){
      redirection = true
    }
    else{redirection = false}
    
    const onKeyDown = (e) => {
      e.preventDefault();
    };  


    return (
      redirection ? <Navigate to="/home" replace={true} /> : 
      <div>      
        <Grid container direction="column" alignItems="flex-start" justifyContent="center" >
          <div hidden={hideFilters}>
            <Grid container direction="row">
            {user && user.is_admin ? 
            <Autocomplete
                  multiple
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  style={classes(theme, isMobile).longField}
                  options={clientOptions}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={clientOptions.length === 1 ? clientOptions : clients}
                  disabled = {clientOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={this.state.clients.length === 1 ? "Client" : this.state.clients.length > 1 ? "Clients" : this.state.countries.length ? "All Clients in Scope" : "All Clients in Cluster"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}, '.MuiSvgIcon-root ': {
                        fill: clientOptions.length === 1 ? "#7f7f7f !important" : "white !important"}, '.MuiChip-root':{background: "grey !important"}
                    }}
                      
                    />
                  )}
                  onChange={this.handleOptionsSelect.bind(this, "client")}
                /> : null}
              <Autocomplete
                  multiple
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  style={classes(theme, isMobile).longField}
                  options={countryOptions}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={countryOptions.length === 1 ? countryOptions : countries}
                  disabled = {countryOptions.length === 1}
                  readOnly = {countryOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={this.state.countries.length === 1 ? "Country" : this.state.countries.length > 1 ? "Countries" : this.state.clients.length ? "All Countries in Scope" : "All Countries in Cluster"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, '.MuiChip-root':{background: "grey !important"}, path:{color: countryOptions.length === 1 ? "#323232 !important" : "#FFFFFF !important"}}}
                    />
                  )}
                  
                  onChange={this.handleOptionsSelect.bind(this, "country")}
                />
                <Autocomplete
                  multiple
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  style={classes(theme, isMobile).longField}
                  options={siteOptions}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={siteOptions.length === 1 ? siteOptions : sites}
                  disabled = {siteOptions.length === 1}
                  readOnly = {siteOptions.length === 1}
                  renderInput={(params) => (
                    <TextField 
                    {...params} 
                    label={this.state.sites.length ? "Site" : this.state.countries.length || this.state.clients.length ? "All Sites in Scope" : "All Sites in Cluster"} 
                    variant="outlined" 
                    sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, '.MuiChip-root':{background: "grey !important"}, path:{color: siteOptions.length === 1 ? "#323232 !important" : "#FFFFFF !important"}}}
                  
                    />
                  )}
                  onChange={this.handleOptionsSelect.bind(this, "site")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  multiple
                  id="building-options"
                  style={classes(theme, isMobile).longField}
                  options={buildingOptions}
                  getOptionLabel={(e) => sites.length > 1 || !sites.length ? e.full_path : e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={buildingOptions.length === 1 ? buildingOptions : buildings}
                  disabled = {buildingOptions.length === 1}
                  readOnly = {buildingOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={this.state.buildings.length ? "Buildings" : this.state.countries.length || this.state.clients.length || this.state.sites.length ? "All Buildings in Scope" : "All Buildings in Cluster"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, '.MuiChip-root':{background: "grey !important"}, path:{color: buildingOptions.length === 1 ? "#323232 !important" : "#FFFFFF !important"}}}
                    />
                  )}
                  onChange={this.handleOptionsSelect.bind(this, "building")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  multiple
                  id="computer-options"
                  style={classes(theme, isMobile).longField}
                  options={computerOptions}
                  getOptionLabel={(e) => sites.length > 1 || !sites.length ? e.full_path : buildings.length > 1 ? e.building_path : e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={computerOptions.length === 1 ? computerOptions : computers}
                  disabled = {computerOptions.length === 1}
                  readOnly = {computerOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={this.state.computers.length ? "Computers" : this.state.countries.length || this.state.clients.length || this.state.sites.length || this.state.buildings.length ? "All Computers in Scope" : "All Computers in Cluster"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, '.MuiChip-root':{background: "grey !important"}, path:{color: computerOptions.length === 1 ? "#323232 !important" : "#FFFFFF !important"}}}
                    />
                  )}
                  onChange={this.handleOptionsSelect.bind(this, "computer")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  multiple
                  id="line-options"
                  style={classes(theme, isMobile).longField}
                  options={lineOptions}
                  getOptionLabel={(e) => sites.length > 1 || !sites.length ? e.full_path : (sites.length === 1 && !buildings.length) || buildings.length > 1 ? e.building_path : (buildings.length === 1 && !computers.length) || computers.length > 1 ? e.computer_path : e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={lineOptions.length === 1 ? lineOptions : lines}
                  disabled = {lineOptions.length === 1}
                  readOnly = {lineOptions.length === 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={this.state.lines.length ? "Lines" : this.state.countries.length || this.state.clients.length || this.state.sites.length || this.state.buildings.length || this.state.computers.length ? "All Lines in Scope" : "All Lines in Cluster"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, '.MuiChip-root':{background: "grey !important"}, path:{color: lineOptions.length === 1 ? "#323232 !important" : "#FFFFFF !important"}}}
                    />
                  )}
                  onChange={this.handleOptionsSelect.bind(this, "line")}
                />
                <Autocomplete
                hidden={!productShow}
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}  
                  multiple
                  style={{...classes(theme, isMobile).longField}}
                  options={products}
                  getOptionLabel={(e) => e.Description}
                  isOptionEqualToValue={(o, v) => o.Description === v.Description}
                  value={this.state.products}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Products"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}

                    />
                  )}
                  onChange={this.handleSelect.bind(this, "products")}
                />
                <Autocomplete
                  sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}}
                  options={orderOptions}
                  hidden={!orderShow || (orderOptions.length === 1 && orderOptions[0].batchnumber === "#placeholder")}
                  multiple
                  style={classes(theme, isMobile).longField}
                  value={orders}
                  isOptionEqualToValue = {(o, v) => o.sid === v.sid}
                  getOptionLabel={(e) => e.batchnumber}
                  onChange={this.handleOptionsSelect.bind(this, "order")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Orders"}
                      variant="outlined"
                      sx={{...classes(theme, isMobile).root, div:{color:"#FFFFFF"}, path:{color:"#FFFFFF"}}}
                      
                    />
                  )}
                />
                <IconButton style={{ marginLeft: "-3.5em",}} 
                  color="primary" aria-label="upload picture" component="label" 
                  onClick={() => {login({username, password: null, mode:'refresh'})}}
                      > 
                  <RefreshIcon style={{color: 'white'}} sx={{ display: this.state.orders.length || !orderShow || (orderOptions.length === 1 && orderOptions[0].batchnumber === "#placeholder") ? "none" : null }}/>
                </IconButton> 
              </Grid>
            </div>
            <div hidden={hideFilters}>
            <div hidden={dateHidden}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {!dateFrom ? null : <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  KeyboardButtonProps={{'aria-label': 'change date',}}
                  label="Date From"
                  onChange = {this.handleDateSet.bind(this, "dateFrom")}
                  renderInput={(params) => <MuiTextField onKeyDown={onKeyDown} sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}} style={classes(theme, isMobile).textField}{...params} />}
                  value={dateStart}
                />}
                {!dateTo ? null : <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Date To"
                  onChange = {this.handleDateSet.bind(this, "dateTo")}
                  renderInput={(params) => <MuiTextField onKeyDown={onKeyDown} sx={{...classes(theme, isMobile).root, svg:{color:"#FFFFFF"}}} style={classes(theme, isMobile).textField}{...params} />}
                  value={dateEnd} 
                />        }
              </LocalizationProvider>
            </div>
            {this.dateWarning}
          </div>          
            <div > 
              <Grid container direction="row" alignItems="center">
                <div hidden={isEqual(currentFilters, latestReportFilters)}>
                  {getPandasRS === LOADING ? null : <Button
                    variant="contained"
                    style={{...classes(theme, isMobile).label, width: theme.spacing(12)}}
                    onClick={this.handleGenerateReport.bind(this)}
                  >
                    {" "}
                    Generate Report{" "}
                  </Button>}
                  {this.dataWarning}
                </div>
                
                {
                  getReportDataFileRS === LOADING ? 
                  <div>
                    <Grid container direction="row" alignItems="center "> <CircularProgress size="1.6rem" style={{...classes(theme, isMobile).label}}/> <Typography style={{...classes(theme, isMobile).label}}> </Typography> </Grid>
                  </div> 
                  : 
                  
                  <div>
                    <Button
                      variant="outlined"
                      onClick={this.handleDownload.bind(this, "csv")}
                      style={{...classes(theme, isMobile).csvButton}}
                      sx={{ display: !csv || getPandasRS === LOADING || !pandasReady ? "none" : null }}
                    > 
                      <Grid container direction="row" alignItems="center">
                        {" "} <DownloadIcon style={{ height: "0.8em", marginLeft: "-0.3em", color: '#059df5'}} /> {" "} 
                        CSV {" "}
                        </Grid>
                    </Button> 
                    
                    <Button
                      variant="outlined"
                      onClick={this.handleDownload.bind(this, "pdf")}
                      style={{...classes(theme, isMobile).pdfButton}}
                      sx={{ display: !pdf || isEmpty(pandas) || getPandasRS === LOADING || !pandasReady ? "none" : null }}
                    > 
                      <Grid container direction="row" alignItems="center">
                        {" "} <DownloadIcon style={{ height: "0.8em", marginLeft: "-0.3em", color: '#059df5' }} /> {" "} 
                        PDF {" "}
                        </Grid> 
                    </Button>  
                  </div> }
                </Grid>
            </div>
          {feedback}
          {this.sites}
          {this.graph}
        </Grid>
      </div>)
  }
}

export default styled(reportSetup)({ theme });
